<style lang="stylus" scoped>
  .content-container {
    position relative
    margin 0 auto
    width 100%
    max-width 960px
    top 5rem
    &::after {
      display table
      content ""
      clear both
    }
  }
  @media (max-width 980px) {
    .content-container {
      padding 0
      top 48px
    }
  }
</style>

<template>
  <div class="template-container">
    <v-header></v-header>
    <nuxt/>
    <v-login></v-login>
  </div>
</template>

<script>
  import VHeader from '~/components/home/header'
  import VLogin from '~/components/login'

  export default {
    components: {
      VHeader,
      VLogin,
    },

    /**
     * 在页面加载前先获取 localStorage 中的用户信息
     */
    mounted() {
      this.$store.dispatch('auth/getUserInfo');
    }
  }
</script>

